exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(true);
// imports


// module
exports.push([module.id, ".ParallaxContentBlock__Text_htmlWrapper *{color:inherit}", "", {"version":3,"sources":["D:/addon-agent-3_work/6/s/Sana.Extensions.ParallaxContentBlock/ClientApp/webstore/components/text/D:/addon-agent-3_work/6/s/Sana.Extensions.ParallaxContentBlock/ClientApp/webstore/components/text/C:/addon-agent/Build Files/Other/SDK/Sources/SDK/Sana.Commerce.WebApp/ClientApp/D:/addon-agent-3_work/6/s/Sana.Extensions.ParallaxContentBlock/ClientApp/webstore/components/text/Text.module.scss","D:/addon-agent-3_work/6/s/Sana.Extensions.ParallaxContentBlock/ClientApp/webstore/components/text/D:/addon-agent-3_work/6/s/Sana.Extensions.ParallaxContentBlock/ClientApp/webstore/components/text/Text.module.scss"],"names":[],"mappings":"AACI,0CACI,aCAR,CAAA","file":"Text.module.scss","sourcesContent":[".htmlWrapper{\r\n    *{\r\n        color: inherit;\r\n    }\r\n}\r\n",".htmlWrapper * {\n  color: inherit;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"htmlWrapper": "ParallaxContentBlock__Text_htmlWrapper"
};