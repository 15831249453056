
    var createContent = function(css) { return typeof css === 'string' ? [[module.id, css, '']] : css; };
    var insertCss = require('isomorphic-style-loader/insertCss');
    var css = require("!!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\cache-loader\\dist\\cjs.js!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\index.js??ref--7-oneOf-5-2!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\postcss-loader\\src\\index.js??postcss!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\sass-loader\\lib\\loader.js??ref--7-oneOf-5-4!./Parallax.scss");

    // Extended with 'style' object as default export.
    var style = { _moduleId: module.id, _content: createContent(css) };

    // Assign values of CSS modules consumed by JS.
    if (css.locals) {
      Object.assign(style, css.locals);
    }

    module.exports = style;

    // https://webpack.github.io/docs/hot-module-replacement
    // Only activated in browser context
    if (module.hot && typeof window !== 'undefined' && window.document) {
      var removeCss = function() {};
      module.hot.accept("!!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\cache-loader\\dist\\cjs.js!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\index.js??ref--7-oneOf-5-2!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\postcss-loader\\src\\index.js??postcss!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\sass-loader\\lib\\loader.js??ref--7-oneOf-5-4!./Parallax.scss", function() {
        var css = require("!!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\cache-loader\\dist\\cjs.js!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\index.js??ref--7-oneOf-5-2!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\postcss-loader\\src\\index.js??postcss!C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\sass-loader\\lib\\loader.js??ref--7-oneOf-5-4!./Parallax.scss");
        style._content = createContent(css);

        var options = style._insertionOptions || {};
        options.replace = true;
        removeCss = insertCss([style], options);
      });
      module.hot.dispose(function() { removeCss(); });
    }
  